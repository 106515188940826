import * as React from 'react'
import { Link } from 'gatsby'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import { fonts } from '../styles/variables'
import HeaderDesktop from '../components/HeaderDesktop'

const H1 = styled.h1`
font-size:1.7rem;
padding-bottom:0.7rem;
font-family: "Theano Didot",Regular;
`
const H2 = styled.h2`
font-size:1.4rem;
padding-bottom:0.7rem;

`

const P = styled.p`
padding: 0% 46px;
font-family: "Theano Didot",Regular;
line-height: 1.5em;
text-align: left;
text-indent: 20px;
margin-top: 1.1%;

letter-spacing: 1px;

 @media screen and (max-width: 1200px) {

   padding: 0% 5%;
   }
`

const PCentered = styled.p`
line-height: 1.5em;
text-align: center;
text-indent: 20px;
margin-top: 1.1%;

letter-spacing: 1px;
`

const DatenschutzContainer = styled.div`
  width: 80%;
text-align:center;
margin-left:auto;
margin-right:auto;
font-family: "Theano Didot", Regular;

padding-top:4%;
font-size: 1.2em;
color:#444;

 @media screen and (max-width: 1200px) {
   width:100%;
   padding: 7% 3%;
   }`

const List = styled.ul`
padding-left:40px;
padding-top:2%;

 @media screen and (max-width: 1200px) {
   padding-left:0px;
   }
`

const H0 = styled.div`
font-family: "Theano Didot", Regular;
font-style: italic;
font-size: 200%;
line-height: 50px;
letter-spacing: 2px;
color:rgb(68, 68, 68);
`

const Datenschutz = ( {pathContext: { locale }}) => (
  <IndexLayout locale={locale}>
    <Helmet

      title={'Datenschutz'}
      meta={[
        { name: 'description', content: 'Vilhelm7 Datenschutzbestimmungen' },
        { name: 'keywords', content: 'Vilhelm7, V7, Apartments, buchen, Hotel, Zimmer, Berlin, Übernachtung, Kreuzberg'},
        {name: 'page-type', content: 'Hotel, Apartments'},
        { name:'copyright', content:"https://www.vilhelm7.de"},
        { name:'X-UA-Compatible', content:"IE=edge"},


      ]}

    />
    <HeaderDesktop></HeaderDesktop>
    <DatenschutzContainer>
<H0 >Datenschutz</H0>
      <br/>
      <br/>
      <br/>
<H1>1. Einleitung</H1>

      <P>{"Mit dem Betrieb unserer Webseite "}

        <a href={'https://vilhelm7.de'}>www.vilhelm7.de</a> (im Folgenden „Webseite“ genannt)

        verarbeiten wir personenbezogene Daten. Diese werden von uns vertraulich behandelt und nach den geltenden Gesetzen - insbesondere der Datenschutzgrundverordnung (DSGVO) und des Bundesdatenschutzgesetzes (BDSG) - verarbeitet. Mit unseren Datenschutzbestimmungen wollen wir Sie informieren, ob und wenn ja, welche personenbezogenen Daten wir von Ihnen erheben, zu welchen Zwecken und auf welcher Rechtsgrundlage wir sie verwenden und gegebenenfalls wem wir sie offenlegen. Darüber hinaus werden wir Ihnen erklären, welche Rechte Ihnen zur Wahrung und Durchsetzung Ihres Datenschutzes zustehen. </P>
   <br/>
      <H2>1.2 Begriffe</H2>

      <P>Unsere Datenschutzbestimmungen enthalten Fachbegriffe, die in der DSGVO und dem BDSG stehen. Zu Ihrem besseren Verständnis wollen wir diese Begriffe in einfachen Worten vorab erklären:
      </P>
<br/>
      <H2>1.3 Personenbezogene Daten</H2>
      <P>„Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare Person beziehen (Art. 4 Nr. 1 DSGVO). Angaben einer identifizierten Person können z.B. der Name oder die E-Mail-Adresse sein. Personenbezogen sind aber auch Daten, bei denen die Identität nicht unmittelbar ersichtlich ist, sich aber ermitteln lässt, indem man eigene oder fremde Informationen kombiniert und so erfährt, um wen es sich handelt. Eine Person wird z.B. über die Angabe ihrer Anschrift oder Bankverbindung, ihres Geburtsdatums oder Benutzernamens oder Standortdaten identifizierbar. Relevant sind hier alle Informationen, die in irgendeiner Weise einen Rückschluss auf eine Person zulassen.</P>
   <br/>
   <H2>1.4 Verarbeitung</H2>
      <P>Unter einer „Verarbeitung“ versteht Art. 4 Nr. 2 DSGVO jeden Vorgang im Zusammenhang mit personenbezogenen Daten. Dies betrifft insbesondere das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung, Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung von personenbezogenen Daten.</P>
    <br/>
    <br/>
      <H1>2. Verantwortliches Unternehmen</H1>
      <PCentered>Verantwortlich für die Datenverarbeitung ist die:</PCentered>
       <PCentered> Unternehmen: 7Arte Group UG (haftungsbeschränkt) <br/>
        Geschäftsführung: Yvonne Kevin, I. Alexander Kevin <br/>
        Anschrift: Wilhelmstr. 7, 10963 Berlin <br/>
         {"Email: "} <a href={'mailto:info@7artegroup.com'}>info@7artegroup.com</a></PCentered>
      <br/>
      <br/>
      <H1>3. Verarbeitungsrahmen</H1>
      <H2>3.1 Verarbeitungsrahmen: Webseite</H2>
      <P>Im Rahmen der Webseite verarbeiten wir die nachfolgend unter Ziffer 4 aufgeführten personenbezogenen Daten von Ihnen. Wir verarbeiten nur Daten von Ihnen, die Sie aktiv auf unserer Webseite angeben (z.B. durch das Ausfüllen von Formularen) oder die Sie bei der Nutzung unseres Angebotes automatisch zur Verfügung stellen. Ihre Daten werden ausschließlich von uns verarbeitet und grundsätzlich nicht an Dritte verkauft, verliehen oder weitergegeben. Sofern wir uns bei der Verarbeitung Ihrer personenbezogenen Daten der Hilfe externer Dienstleister bedienen, erfolgt dies im Rahmen einer sogenannten Auftragsverarbeitung, bei der wir als Auftraggeber unseren Auftragnehmern gegenüber weisungsbefugt sind. Zum Betrieb unserer Webseite setzen wir für teilweise für das Billing sowie das Hosting sowie zur Wartung, Pflege und Weiterentwicklung externe Dienstleister ein. Sollten bei einzelnen, der in Ziffer 2 aufgeführten Verarbeitungen weitere externe Dienstleister zum Einsatz kommen, werden diese dort benannt. Wir hosten unsere Webseite beim externen Cloud-Anbieter Amazon Webservices AWS (Amazon Web Services, Inc., 410 Terry Avenue North, Seattle, WA 98109-5210, USA) im Rechenzentrumsstandort Frankfurt a.M.</P>
    <br/>
    <br/>
    <H1>4. Verarbeitungen im Einzelnen</H1>
      <H2>4.1 Bereitstellung der Webseite und Logfiles</H2>
      <P>Bei jedem Aufruf der Webseite erfassen wir automatisch Informationen, die Ihr Browser an unseren Server übermittelt. Diese werden auch in den sogenannten Logfiles unseres Systems gespeichert. Dabei handelt es sich um die folgenden Daten:</P>
    <List>
      <li>Ihre IP-Adresse</li>
      <li>die von Ihnen verwendete Browsersoftware, sowie deren Version und Sprache</li>
      <li>das von Ihnen verwendete Betriebssystem</li>
      <li>die Webseite, von der Sie auf unsere Webseite gelangt sind</li>
      <li>das Datum und die Uhrzeit Ihres Aufrufes unserer Webseite</li>
      <li>Ihr Internet-Service-Provider</li>
      <li>Übertragene Datenmenge</li>
      <li>Land und Ort aus dem Sie unsere Webseite besucht haben</li>
      <li>Ihre Aufenthaltsdauer auf unserer Webseite</li>
    </List>
      <br/>
      <P>Die vorübergehende Speicherung Ihrer IP-Adresse durch das System ist notwendig, um unsere Webseite an das Endgerät eines Nutzers ausliefern zu können, um uns vor Missbrauch (Betrug) zu schützen und im Zweifelsfall eine Strafverfolgung zu ermöglichen. Hierfür muss und wird die IP-Adresse temporär gespeichert werden, sie wird aber spätestens nach einem Zeitraum von 14 Tagen wieder gelöscht und nicht mit Ihren sonstigen personenbezogenen Daten verknüpft.

        Die Verarbeitung erfolgt, um den Aufruf der Webseite zu ermöglichen, sowie deren Stabilität und Sicherheit zu gewährleisten. Darüber hinaus dient die Verarbeitung der statistischen Auswertung und Verbesserung unseres Online-Angebotes.

        Die Verarbeitung ist zur Wahrung der überwiegenden berechtigten Interessen des Verantwortlichen erforderlich (Art. 6 Abs. 1 lit. f DSGVO).

        Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung der Daten zur Bereitstellung der Website ist dies der Fall, wenn die jeweilige Sitzung beendet ist. Eine Löschung der Logfiles erfolgt nach spätestens 14 Tagen.</P>
    <br/>
    <H2>4.2 Registrierung und Profil</H2>
      <P>Einzelne Funktionen und Angebote unserer Webseite stehen Ihnen nur als registrierter Nutzer zur Verfügung. Mit der Registrierung schließen Sie einen kostenlosen Nutzungsvertrag mit uns ab. Sie erhalten durch die Registrierung einen eigenen Nutzer-Account auf unserer Webseite. Die Registrierung erfolgt, indem Sie das Registrierungsformular ausfüllen und uns elektronisch übermitteln. Zur Registrierung müssen Sie Ihren Vor- und Nachnamen, Ihre E-Mail-Adresse sowie ein frei wählbares Passwort (zweifach, identisch) angeben. Nach Anklicken der entsprechenden Checkbox zur Zustimmung zu unseren Datenschutzbestimmungen übermitteln Sie uns mit dem Anklicken des Buttons „Absenden“ das Formular bzw. die angegebenen Daten. Sie erhalten dann eine automatische Willkommens-E-Mail an die von Ihnen angegebene E-Mail-Adresse.

        Als registrierter Nutzer können Sie auf unserer Webseite schneller und bequemer buchen, indem Sie zum Beispiel Ihre Rechnungs- und Lieferanschriften in Ihrem Nutzer-Profil hinterlegen. Dadurch müssen Sie bestimmte personenbezogenen Daten bei späteren (weiteren) Einkäufen nicht erneut eingeben.

        Die Verarbeitung erfolgt, um Ihnen die Funktionen unserer Webseite für registrierte Nutzer bereitstellen zu können und Ihnen somit die einfachere Buchung von Inseraten auf unserer Webseite zu ermöglichen.

        Die Verarbeitung ist zum Abschluss und zur Erfüllung des Nutzungsvertrages erforderlich (Art. 6 Abs. 1 lit. b DSGVO). Ohne Angabe Ihrer personenbezogenen Daten im Rahmen der Registrierung bzw. in Ihrem Nutzer-Profils können wir unsere vertraglich geschuldeten Leistungen nicht erbringen.

      {"Ihre Daten werden (nach Registrierung ohne Buchung) von uns mit Beendigung Ihres kostenlosen Nutzungsvertrages automatisch gelöscht. Den Nutzungsvertrag können Sie selbständig beenden, indem uns per E-Mail an: "} <a href={'mailto:info@vilhelm7.de'}>info@vilhelm7.de</a>, per Post an: 2inSeven GmbH, Kundenservice, Wilhelmstr. 7, 10963 Berlin mitteilen, dass Sie kein registrierter Nutzer unserer Webseite mehr sein wollen.</P>
      <br/>
      <H2>4.3 Buchung</H2>
      <P>Im Rahmen Ihres Buchungsprozesses verarbeiten wir personenbezogene Daten von Ihnen. Sie können als Gast oder als registrierter Nutzer auf unserer Webseite buchen. Wenn Sie als registrierter Nutzer auf unserer Webseite buchen, können Sie Ihre Rechnungs- und Lieferanschriften, sowie die von Ihnen bevorzugte Zahlungsart für eine schnellere und bequemere Buchung bei uns in Ihrem Nutzer-Profil hinterlegen.

        Wenn Sie als Gast buchen, müssen Sie bei Ihrer Buchung das Buchungsformular ausfüllen. Die auf unserer Website mit einem Sternchen markierten Pflichtfelder (E-Mail-Adresse, Vorname, Nachname, Postleitzahl) müssen von Ihnen eingetragen werden. Ansonsten ist es uns nicht möglich, mit Ihnen einen Kaufvertrag zu schließen und Ihnen die gewünschte Ware zuzusenden. Alle weiteren Angaben sind freiwillig. Bei einer Buchung auf unserer Webseite können Sie darüber hinaus eine der angebotenen Zahlungsarten (Kreditkarte, PayPal oder Vorkasse) zur Begleichung der Buchung auswählen.

        Beim Abschluss Ihrer Buchung werden die für die Zahlung erforderlichen Daten an den jeweiligen Zahlungsdienstleister weitergegeben.

        Die Verarbeitung erfolgt zum Abschluss und zur Abwicklung von Kaufverträgen und zu Ihrer Information per E-Mail über den aktuellen Status Ihrer Reservierung.

        Wir sind aufgrund von handels- und steuerrechtlichen Vorgaben verpflichtet, Ihre Adress-, Zahlungs- und Buchungsdaten für die Dauer von zehn Jahren zu speichern.

        {"Die Einwilligung in die Übermittlung Ihrer E-Mail-Adresse zur Versendung von E-Mails ist freiwillig und kann von Ihnen jederzeit durch einfache Erklärung (per E-Mail an: "} <a href={'mailto:info@vilhelm7.de'}>info@vilhelm7.de</a>, per Post an: 2inSeven GmbH, Wilhelmstr. 7, 10963 Berlin mit Wirkung für die Zukunft widerrufen werden.

        Zur Abwicklung Ihrer Zahlung werden personenbezogene Daten, insbesondere Vor- und Nachname, Adresse, E-Mail-Adresse, sowie Daten, die im Zusammenhang mit der Buchung stehen, wie Rechnungsbetrag, Steuern in Prozent und Rechnungsinformationen an einen der nachfolgend aufgeführten und von Ihnen im Rahmen Ihrer Buchung mit der Zahlungsart ausgewählten Zahlungsdienstleister weitergegeben.</P>
    <br/>
    <List>
      <li>{"PayPal und PayPal Express sind Dienste der PayPal (Europe) S.à r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxemburg. Um über PayPal oder PayPal-Express bezahlen zu können, müssen Sie über ein PayPal-Konto verfügen. PayPal behält sich vor, personenbezogene Daten personenbezogenen Daten unter Umständen zur Identitäts- und Bonitätsprüfung an Wirtschaftsauskunfteien zu übermitteln. Weitere Informationen zum Datenschutz bei PayPal finden Sie unter "} <a href={'https://www.paypal.com/de/webapps/mpp/ua/privacy-full?locale.x=de_DE'}>https://www.paypal.com/de/webapps/mpp/ua/privacy-full?locale.x=de_DE</a>.</li>
      <li>{"Kreditkarte, iDEAL, Klarna, Giropay: Die Zahlungsabwicklung findet über die Stripe, Inc. 510 Townsend Street San Francisco, CA 94103, USA statt. Weitere Informationen zum Datenschutz bei Stripe finden Sie unter "} <a href={'https://stripe.com/de/privacy'}>https://stripe.com/de/privacy</a>.</li>
      <li>Vorkasse: Es findet keine Datenweitergabe an dritte Unternehmen statt.</li>
    </List>
      <br/>
      <br/>
      <H2>4.4 Cookies</H2>
      <P>Bei Cookies handelt es sich um kleine Textdateien, die beim Besuch einer Webseite auf dem Endgerät des Nutzers (des von Ihnen genutzen Endgerätes) gespeichert werden. Cookies enthalten Informationen, die die Wiedererkennung eines Endgerätes und ggf. bestimmte Funktionen einer Webseite ermöglichen. Zumeist setzen wir lediglich so genannte „Session-Cookies“ ein.

        Wir setzen Cookies ein, um unsere Webseite nutzerfreundlicher zu gestalten und die unter Ziffer 4.4 beschriebenen Funktionen anzubieten.

        Die Verarbeitung ist zur Wahrung der überwiegenden berechtigten Interessen des Verantwortlichen erforderlich (Art. 6 Abs. 1 lit. f DSGVO). Sofern Sie von uns im Rahmen eines Cookie-Banners bzw. Cookie-Consent-Tools um eine Einwilligung gebeten werden, besteht die Rechtsgrundlage in Art. 6 Abs. 1 lit. a DSGVO. Eine solche Einwilligung ist freiwillig.

        Cookies werden automatisch mit dem Ende einer Sitzung bzw. mit Ablauf der angegebenen Speicherdauer gelöscht. Da Cookies auf Ihrem Endgerät gespeichert werden, haben Sie als Nutzer auch die volle Kontrolle über die Verwendung von Cookies. Durch eine Änderung der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von Cookies deaktivieren oder einschränken. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen. Werden Cookies für unsere Webseite deaktiviert, gelöscht oder eingeschränkt, kann es sein, dass einzelne Funktionen unserer Webseite nicht oder nur eingeschränkt genutzt werden können. Sofern wir Einwilligungen zur Verwendung von Cookies über ein Cookie-Banner oder ein Cookie-Consent-Tool einholen, können diese Einwilligungen von Ihnen jederzeit innerhalb der Einstellungen des Cookie-Banners bzw. des Cookie-Consent-Tools mit Wirkung für die Zukunft widerrufen werden.
      </P>
      <br/>
      <H2>4.5 Newsletter</H2>
      <P>Wir versenden in unregelmäßigen Abständen einen Newsletter. Mit dem Newsletter informieren wir Sie über interessante Neuigkeiten auf unserer Webseite oder in unserem Shop. Unseren Newsletter erhalten Sie nur, wenn Sie sich aktiv in über das Ausfüllen eines Newsletter-Anmeldeformulars unseren Verteiler eintragen. Zur Durchführung und Verifizierungen von Newsletter-Anmeldungen setzen wir das sogenannte Double-Opt-in-Verfahren ein. Eine Anmeldung erfolgt in mehreren Schritten. Zunächst tragen Sie sich auf unserer Webseite in den Newsletter ein. Sie erhalten an die von Ihnen angegebene E-Mail-Adresse dann eine E-Mail von uns. Mit dieser E-Mail bitten wir Sie um die Bestätigung, dass Sie sich tatsächlich in den Newsletter eingetragen haben und dessen Zusendung wünschen. Eine Bestätigung erfolgt über das Anklicken eines, in der E-Mail befindlichen Bestätigungslinks. Erst nach einer erfolgreichen Bestätigung werden wir Sie in unseren Newsletter-Verteiler aufnehmen und Ihnen zukünftig E-Mails zusenden. Im Rahmen des Double-Opt-In-Verfahrens speichern wir sowohl bei der Anmeldung, als auch bei der Bestätigung Datum, Uhrzeit und Ihre IP-Adresse. Wenn Sie auf unserer Internetseite Waren käuflich erwerben und hierbei Ihre E-Mail-Adresse hinterlegen, kann diese in der Folge durch uns für den Versand eines sogenannten Bestandskunden-Mailings verwendet werden. In einem solchen Fall wird über die Mailings ausschließlich Direktwerbung für eigene ähnliche Waren oder Dienstleistungen versendet.

        Die Verarbeitung erfolgt, um die Newsletter-Funktion anbieten, sowie Bestandskunden-Mailings zusenden zu können. Die Erhebung und Speicherung von Datum, Uhrzeit und IP-Adressen bei der Newsletter-Anmeldung dient der Dokumentation von erteilten Einwilligung und dem Schutz vor der missbräuchlichen Eintragung von E-Mail-Adressen.

        Die Verarbeitung bei unseren Newslettern erfolgt auf Grundlage einer Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO. Die Erhebung und Speicherung von Datum, Uhrzeit und IP-Adressen bei der Newsletter-Anmeldung ist zur Wahrung der überwiegenden berechtigten Interessen des Verantwortlichen erforderlich (Art. 6 Abs. 1 lit. f DSGVO). Die Verarbeitung bei unseren Bestandskunden-Mailings erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO zur Wahrung der überwiegenden Interessen des Verantwortlichen. Unser berechtigtes Interesse liegt in der Direktwerbung an Bestandskunden. Diese ist in dem von uns beachteten Rahmen von § 7 Abs. 3 UWG zulässig.

        Wir verarbeiten Ihre personenbezogenen Daten im Übrigen für die Dauer Ihres Newsletter-Abonnements. Sie können den Bezug unseres Newsletters jederzeit durch Widerruf ihrer Einwilligung beenden. Dafür genügt eine einfache Erklärung. (per E-Mail an: info@vilhelm.de, per Post an: 2inSeven GmbH, Wilhelmstr. 7, 10963 Berlin) <br/>Eine Abbestellung des Newsletters ist zudem durch das Anklicken des Links zur Abbestellung in jeder Newsletter-E-Mail möglich. Mit dem Widerruf ihrer Einwilligung werden Ihnen keine Newsletter mehr zugesendet und ihre personenbezogenen Daten aus unserem aktiven Verteiler entfernt.</P>
    <br/>
    <H2>4.6 Soziale Netzwerke</H2>
      <P>Unsere Webseite verwendet keine sogenannten Social Media Plugins. Die auf unserer Webseite angezeigten Logos der Sozialen Netzwerke Facebook, Instagram, Youtube und Pinterest sind lediglich mit den entsprechenden Profilen unseres Unternehmens verlinkt. Wenn Sie eines der Logos anklicken, werden Sie auf die externe Webseite des jeweiligen Sozialen Netzwerks weitergeleitet.

        Eine Datenverarbeitung stellen jedoch auch unsere Profile innerhalb der Sozialen Netzwerke dar. Sofern Sie beim Besuch eines solchen Profils beim jeweiligen Sozialen Netzwerk eingeloggt sind, werden diese Informationen Ihrem dortigen Benutzerkonto zugeordnet. Wenn Sie mit unserem Profil interagieren, z.B. einen Beitrag „teilen“ oder „liken“, werden auch diese Informationen in Ihrem Benutzerkonto gespeichert. Über die sogenannten „Insights“ unserer Facebook-Seite haben wir die Möglichkeit, statistische Daten zu erhalten. Diese Statistiken werden von Facebook bereitgestellt. Die „Insights-Funktion“ ist nicht abdingbar. Wir können nicht entscheiden, diese Funktion an- oder auszuschalten. Sie steht allen Facebook-Fanpage-Betreibern zur Verfügung, unabhängig davon, ob Sie die Insights-Funktion von Facebook nutzen oder nicht. Uns werden Daten für einen wählbaren Zeitraum und für den folgenden Kreis betroffener Personengruppen bereitgestellt: Fans, Abonnenten, erreichte Personen und interagierende Personen. Es handelt sich dabei um folgende Kategorien personenbezogener Daten: Gesamtzahl von Seitenaufrufen, „Gefällt-mir“-Angaben einschließlich Herkunft, Seitenaktivitäten, Beitragsinteraktionen, Reichweite, Beitragsreichweite (unterteilt in organische, virale und bezahlte Interaktionen), Kommentare, Geteilte Inhalte, Antworten sowie demografische Auswertungen, also Herkunftsland, Geschlecht und Alter. Aufgrund der Facebook-Nutzungsbestimmungen – denen jeder Benutzer zugestimmt haben muss um Facebook nutzen zu können – ist es uns möglich, Abonnenten und Fans unserer Seite zu identifizieren und deren Profile einzusehen.

        Die Sozialen Netzwerke, mit denen Sie kommunizieren, speichern Ihre Daten unter Verwendung von Pseudonymen als Nutzungsprofile und verwenden sie für Werbezwecke und zur Marktforschung. So können Ihnen z.B. Werbeanzeigen innerhalb des Sozialen Netzwerkes und auf anderen Webseiten von Dritten angezeigt werden, die Ihren mutmaßlichen Interessen entsprechen. Zu diesem Zweck kommen im Regelfall Cookies zum Einsatz, die das Soziale Netzwerk auf Ihrem Endgerät ablegt. Ihnen steht gegen die Bildung dieser Nutzerprofile ein Widerspruchsrecht zu, für dessen Ausübung Sie sich direkt an die Sozialen Netzwerke wenden müssen.

        Bezüglich unserer Profile auf Sozialen Netzwerken weisen wir darauf hin, dass wir diese mit dem Dienst Falcon.io verwalten. Dabei handelt es sich um einen Social-Media-Management Dienst, das von der Falcon.io ApS, H.C. Andersens boulevard 27, 1st floor, 1553 Copenhagen V, Denmark betrieben wird. Mit Falcon.io können wir unsere sämtlichen Präsenzen in Sozialen Netzwerken zentral verwalten und betreuen. Wir können Beiträge veröffentlichen, Nutzer-Reaktionen bearbeiten, die Sozialen Netzwerke nach Erwähnungen durchsuchen sowie sämtliche Interaktionen mit uns und unsere Maßnahmen analysieren. Bei jeder Interaktion mit unseren Profilen in Sozialen Netzwerken werden die Daten von Ihnen innerhalb unseres Kontos bei Falcon.io gespeichert, die in den jeweils von Ihnen genutzten Profilen hinterlegt sind. Das sind bspw. Name, Geschlecht, Profilbild, Profil-URL, Handle/Benutzername. Außerdem werden die Zeitpunkte Ihrer Interaktionen mit unseren Social-Media-Profilen erfasst.

        Wir unterhalten Profile bei den vorgenannten Sozialen Netzwerken zum Zwecke der zeitgemäßen und unterstützenden Öffentlichkeitsarbeit und Unternehmenskommunikation mit Kunden und Interessierten. Um diese zentral und effizient verwalten und betreuen zu können, setzen wir den Social-Media-Management-Dienst Falcon.io ein. Die Funktion „Facebook-Insights“ nutzen wir, um unsere Beiträge auf unserer Facebook-Fanpage für unsere Besucher anziehender zu machen. So ist uns etwa möglich, favorisierte Besuchszeiten der Besucher für eine zeitlich optimierte Planung unserer Beiträge zu nutzen.

        Rechtsgrundlage für die Datenverarbeitung im Rahmen unserer Profile auf Sozialen Netzwerken und dem Einsatz des Dienstes Falcon.io ist die Wahrung unserer überwiegenden berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO). Sofern Sie vom jeweiligen Betreiber eines Sozialen Netzwerkes um eine Einwilligung gebeten werden, besteht die Rechtsgrundlage in Art. 6 Abs. 1 lit. a DSGVO. Die Datenverarbeitung erfolgt im Hinblick auf unsere Facebook-Fanpage im Übrigen auf Grundlage einer Vereinbarung über eine gemeinsame Verantwortlichkeit gemäß Art. 26 DSGVO zwischen uns und Facebook, die Sie hier einsehen können: https://www.facebook.com/legal/terms/page_controller_addendum

        Die jeweiligen Sozialen Netzwerke werden von den nachfolgend aufgeführten Unternehmen betrieben. Weitere Informationen zum Datenschutz im Hinblick auf unser Profil auf den Sozialen Netzwerken finden Sie in den verlinkten Datenschutzbestimmungen.</P>
<br/>
<H2>4.7 Google Analytics</H2>
      <P>Unsere Webseite verwendet „Google Analytics“, einen Webanalysedienst der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4. Ireland (nachfolgend „Google“ genannt). Google Analytics setzt Cookies ein (siehe Ziffer 4.4), die eine Analyse Ihrer Nutzung unseres Angebotes ermöglichen. Wir verwenden Google Analytics in der angebotenen Version „Universal Analytics“, die diese Analyse geräteübergreifend durch Zuordnung der Daten zu einer pseudonymen User-ID erlaubt. Die durch den Cookie erzeugten Informationen werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Wir verwenden Google Analytics jedoch ausschließlich mit einer IP-Anonymisierung. Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Die von Google Analytics erstellten Statistiken erfassen insbesondere wie viele Nutzer unsere Website besuchen, aus welchem Land bzw. Ort der Zugriff erfolgt, welche Unterseiten aufgerufen werden und über welche Links oder Suchbegriffe Besucher auf unsere Webseite gelangen. Die Nutzerbedingungen von Google Analytics finden Sie unter

        https://marketingplatform.google.com/about/analytics/terms/de


        Merh Informationen zum Datenschutz bei Google Analytics finden Sie unter

        https://support.google.com/analytics/answer/6004245?hl=de&ref_topic=2919631



        Die Datenschutzerklärung von Google finden Sie unter

        https://policies.google.com/privacy?hl=de&gl=de


        Unsere Website verwendet ferner Google Optimize. Dabei handelt es sich um ein Tool, das in Google Analytics integriert ist. Google Optimize analysiert die Nutzung verschiedener Varianten unserer Website und hilft uns die Benutzerfreundlichkeit entsprechend dem Verhalten der Besucher unserer Webseite zu verbessern. Mittels Google Optimize können wir neue Funktionen und Inhalte einem prozentualen Anteil unserer Nutzer ausspielen und statistisch auswerten, wie diese Änderungen angenommen werden. Google Optimize setzt dafür ebenfalls Cookies ein. Wir verwenden Google Optimize ausschließlich mit einer aktivierten IP-Anonymisierung gem. der vorstehenden Ausführungen zu Google Analytics.

        Die Verarbeitung erfolgt, um die Nutzung unserer Webseite auswerten zu können. Die dadurch gewonnenen Informationen dienen der Verbesserung und bedarfsgerechten Gestaltung unseres Online-Auftritts.

        Die Verarbeitung ist zur Wahrung der überwiegenden berechtigten Interessen des Verantwortlichen erforderlich (Art. 6 Abs. 1 lit. f DSGVO). Unser berechtigtes Interesse liegt in dem, in Ziffer 4.7 benannten Zweck.

        Die Speicherdauer, sowie Ihre Kontroll- und Einstellungsmöglichkeiten bei Cookies haben wir in Ziffer 4.4 erläutert. Sie können darüber hinaus die Datenerfassung durch Google Analytics verhindern, indem Sie das von Google unter

        angebotene Browser-Add-on herunterladen und installieren. Die mit Google Analytics verarbeiteten und gespeicherten Analysedaten werden von uns nach 50 Monaten automatisch gelöscht.

        Alternativ zum Browser-Add-On oder innerhalb von Browsern auf mobilen Geräten,

        um die Erfassung durch Google Analytics innerhalb dieser Website zukünftig zu verhindern (das Opt Out funktioniert nur in diesem Browser und nur für diese Domain). Dabei wird ein Opt-Out-Cookie auf Ihrem Gerät abgelegt. Löschen Sie Ihre Cookies in diesem Browser, müssen Sie diesen Link erneut klicken.

        Sofern wir Einwilligungen zum Einsatz von Google Analytics über ein Cookie-Banner oder ein Cookie-Consent-Tool einholen, können solche Einwilligungen von Ihnen jederzeit innerhalb der Einstellungen des Cookie-Banners bzw. des Cookie-Consent-Tools mit Wirkung für die Zukunft widerrufen werden.

        Google Analytics ist für uns im Rahmen einer Auftragsverarbeitung als Dienstleister tätig. Google verarbeitet Ihre personenbezogenen Daten auch in den USA und hat sich dem EU-US-Privacy-Shield unterworfen. Weitere Informationen zum EU-US-Privacy-Shield finden Sie unter

        https://www.privacyshield.gov/EU-US-Framework
      </P>
      <br/>
      <H2>4.8 Google Tag Manager</H2>
      <P>Unsere Webseite verwendet den „Google Tag Manager“, einen Dienst des Unternehmens Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (nachfolgend „Google“ genannt). Über den Google Tag Manager werden keine personenbezogenen Daten erfasst und keine Cookies gesetzt. Dieser Dienst ermöglicht uns lediglich die Einbindung und Verwaltung von Tags auf unserer Webseite. Tags sind kleine Codeelemente auf unserer Website, die hilfreich sind, um darauf aufbauend mit anderen Tools Traffic und Besucherverhalten zu messen, die Auswirkung von Online-Werbung und sozialen Kanälen zu erfassen, Remarketing und die Ausrichtung auf Zielgruppen einzusetzen, die Webseite zu testen und zu optimieren. Weitere Informationen zum Google Tag Manager finden Sie hier:

        https://www.google.com/intl/de/tagmanager/use-policy.html
      </P>
      <br/>
      <H2>4.9 Twitter Ads</H2>
      <P>Unsere Webseite verwendet den Remarketing-Dienst „Twitter Ads“, der von Twitter Inc., 1355 Market St, Suite 900, San Francisco, California 94103, USA betrieben wird („Twitter“). Über „Twitter Ads“ ist es uns möglich, auf dem sozialen Netzwerk Werbeanzeigen zu schalten, die sich zielgenau an diejenigen Twitter-Nutzer wenden, die Interesse an unserem Angebot gezeigt haben – z.B. durch einen früheren Besuch auf unserer Webseite. Zu diesem Zweck binden wir auf unserer Webseite ein Twitter Pixel über den Google Tag Manager ein. Mit diesem Pixel kann Twitter einerseits Ihr Interesse an unserem Angebot erkennen, zum anderen können wir darüber die Wirksamkeit und die Reichweite unserer Werbung auf Twitter nachvollziehen und auswerten. Denn über den Dienst Twitter Ads wird erfasst, ob Twitter-Nutzer mit unseren Werbeanzeigen auf dem sozialen Netzwerk interagieren durch das Anklicken der Werbeanzeigen auf unsere Webseite weitergeleitet werden. Wenn Sie unsere Webseite besuchen wird daher eine Verbindung zu den Twitter-Servern hergestellt und das „Twitter-Pixel“ in unsere Webseite eingebettet. Zusätzlich kann es sein, dass Twitter auf Ihrem Endgerät ein Cookie speichert. Wenn Sie bei Twitter eingeloggt sind oder sich später bei Twitter einloggen, wird Ihr Besuch auf unserer Webseite Ihrem Benutzerkonto zugeordnet. Die über Sie mittels Twitter Pixels erhobenen Daten sind für uns anonym. Sie liefern uns keinerlei Rückschlüsse auf Ihre Person. Jedoch ist seitens Twitter eine Verbindung zu Ihrem Nutzerprofil möglich. Die Datenverarbeitung durch Twitter erfolgt gemäß der Datenschutzbestimmungen des Unternehmens. Weitere Informationen finden Sie unter:

        https://twitter.com/privacy



        Die Verarbeitung erfolgt, um zielgenaue Online-Werbung für unsere eigenen Angebote durchführen und deren Wirksamkeit und Reichweite auswerten zu können.

        Die Speicherdauer, sowie Ihre Kontroll- und Einstellungsmöglichkeiten bei Cookies haben wir in Ziffer 4.4 erläutert. Sofern wir Einwilligungen zum Einsatz des Twitter Ads über ein Cookie-Banner oder ein Consent-Tool einholen, können solche Einwilligungen von Ihnen jederzeit innerhalb der Einstellungen des Cookie-Banners bzw. des Consent-Tools mit Wirkung für die Zukunft widerrufen werden. Sie können der Datenerhebung durch das „Twitter Ads“ und der Verwendung Ihrer Daten zur Darstellung von Twitter-Werbeanzeigen jederzeit widersprechen. Hierzu können Sie den in den Einstellungen Ihres Twitter-Accounts unter dem Reiter „Individualisierung und Daten“ die Einstellung „Personalisierte Anzeigen“ anpassen. Weitere Informationen dazu finden Sie unter:

        https://help.twitter.com/de/safety-and-security/privacy-controls-for-tailored-ads


        Durch die Nutzung von „Twitter Ads“ werden ggf. personenbezogenen Daten an Twitter übermittelt. Twitter verarbeitet Ihre personenbezogenen Daten auch in den USA und hat sich dem EU-US-Privacy-Shield unterworfen. Weitere Informationen zum EU-US-Privacy-Shield finden Sie unter:

        https://www.privacyshield.gov/EU-US-Framework
      </P>
      <br/>
      <br/>
      <H1>5. Sicherheitsmaßnahmen</H1>
      <P>Um Ihre persönlichen Daten vor fremdem Zugriff zu schützen, haben wir unsere Webseite mit einem SSL- bzw. TLS-Zertifikat versehen. SSL steht für „Secure-Sockets-Layer“ und TLS für „Transport Layer Security“ und verschlüsselt die Kommunikation von Daten zwischen einer Webseite und dem Endgerät des Nutzers. Sie erkennen die aktive SSL- bzw. TLS-Verschlüsselung an einem kleinen Schloss-Logo, das ganz links in der Adresszeile des Browsers angezeigt wird.</P>
   <br/>
   <br/>
   <H1>6. Ihre Rechte</H1>
      <H2>6.1 Auskunft (Art. 15 DSGVO)</H2>
      <P>Im Hinblick auf die oben beschriebene Datenverarbeitung durch unser Unternehmen stehen Ihnen die folgenden Betroffenenrechte zu:
      </P>
      <br/>
      <H2>6.2 Berichtigung (Art. 16 DSGVO)</H2>
      <P>Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen, ob wir Sie betreffende personenbezogene Daten verarbeiten. Ist dies der Fall, so hat sie unter den in Art. 15 DSGVO genannten Voraussetzungen ein Recht auf Auskunft über diese personenbezogenen Daten und auf die in Art. 15 DSGVO im einzelnen aufgeführten Informationen.
      </P>
      <br/>
      <H2>6.3 Löschung (Art. 17 DSGVO)</H2>
      <P>Sie haben das Recht, von uns unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten und ggf. die Vervollständigung unvollständiger personenbezogener Daten zu verlangen.</P>
      <br/>
      <H2>6.4 Einschränkung der Datenverarbeitung (Art. 18 DSGVO)</H2>
      <P>Sie haben das Recht, von uns zu verlangen, dass Sie betreffende personenbezogene Daten unverzüglich gelöscht werden, sofern einer der in Art. 17 DSGVO im einzelnen aufgeführten Gründe zutrifft, z. B. wenn Ihre Daten für die von uns verfolgten Zwecke nicht mehr benötigt werden.
      </P>
      <br/>
      <H2>6.5 Datenübertragbarkeit (Art. 20 DSGVO)
      </H2>
      <P>Sie haben das Recht, von uns die Einschränkung der Verarbeitung zu verlangen, wenn eine der in Art. 18 DSGVO aufgeführten Voraussetzungen gegeben ist, z. B. wenn Sie die Richtigkeit Ihrer personenbezogenen Daten bestreiten, wird die Datenverarbeitung für die Dauer eingeschränkt, die uns die Überprüfung der Richtigkeit Ihrer Daten ermöglicht.
      </P>
      <br/>
      <H2>6.6 Widerruf von Einwilligungen (Art. 7 Abs. 3 DSGVO)
      </H2>
      <P>Sie haben das Recht, unter den in Art. 20 DSGVO aufgeführten Voraussetzungen, die Herausgabe der Sie betreffenden Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu verlangen.</P>
      <br/>
      <H2>6.7 Beschwerde (Art. 77 DSGVO)</H2>
      <P>Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt, haben Sie das Recht auf Beschwerde bei einer Aufsichtsbehörde. Sie können dieses Recht bei einer Aufsichtsbehörde in dem EU-Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes geltend machen.</P>
      <br/>
      <H2>6.8 Verbot automatisierter Entscheidungen/ Profiling (Art. 22 DSGVO)
      </H2>
      <P>Entscheidungen, die für Sie rechtliche Folge nach sich ziehen oder Sie erheblich beeinträchtigen, dürfen nicht ausschließlich auf eine automatisierte Verarbeitung personenbezogener Daten – einschließlich eines Profiling – gestützt werden. Wir teilen Ihnen mit, dass wir im Hinblick auf Ihre personenbezogenen Daten keine automatisierte Entscheidungsfindung einschließlich Profiling einsetzen.</P>
      <br/>
      <H2>6.9 Widerspruch (Art. 21 DSGVO)</H2>
      <P>Wenn wir personenbezogene Daten von Ihnen auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO (zur Wahrung überwiegender berechtigten Interessen) verarbeiten, haben Sie das Recht, unter den in Art. 21 DSGVO aufgeführten Voraussetzungen dagegen Widerspruch einzulegen. Dies gilt jedoch nur, soweit Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben. Nach einem Widerspruch verarbeiten wir Ihre personenbezogenen Daten nicht mehr, es sei denn wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen. Wir müssen die Verarbeitung ebenfalls nicht einstellen, wenn sie der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient. In jedem Fall – auch unabhängig von einer besonderen Situation – haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten für Direktwerbung einzulegen.
      </P>
      <br/>
    </DatenschutzContainer>
  </IndexLayout>
)

export default Datenschutz
